<template>
  <div>
    <!-- <base-page-title :titleText="pageTitle" /> -->
    <create-project-section />
    <project-list-dashboard />
  </div>
</template>

<script>
// import BasePageTitle from "./../components/Base/SimplePageTitle";
import CreateProjectSection from "./../components/Dashboard/CreateProjectSection";
import ProjectListDashboard from "./../components/Dashboard/ProjectListDashboard";
export default {
  components: {
    // BasePageTitle,
    CreateProjectSection,
    ProjectListDashboard
  },
  data() {
    return {
      pageTitle: "page.projects.title"
    };
  }
};
</script>

<style scoped></style>
