<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0"
    centered
    scrollable
    :ok-title="$t('button.close')"
    ok-variant="primary"
    ok-only
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase">
        {{ $t("page.respondentURL.title") }}
      </h5>
    </template>
    <template v-slot:default>
      <div v-for="(stimulus, index) in stimulusList" :key="index">
        <b-row class="no-gutters">
          <b-col class="col-12 col-md-auto">
            <video preload="metadata" :src="`${stimulus.src}#t=1.5`"></video>
          </b-col>
          <b-col class="col-12 col-md pl-0 pl-md-3">
            <div class="mb-3">
              <span class="font-weight-bold mr-2">
                {{ $t("page.respondentURL.videoName") }}
              </span>
              <span>{{ stimulus.name }}</span>
            </div>
            <b-input-group v-if="stimulus.testSurveyUrl">
              <b-form-input
                :value="stimulus.testSurveyUrl"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="red"
                  size="sm"
                  class="input-append-btn"
                  @click="visitURL(stimulus.testSurveyUrl)"
                >
                  {{ $t("page.respondentURL.visitBtn") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else class="text-uppercase font-italic">
              {{ $t("page.respondentURL.urlNotAvailable") }}
            </div>
          </b-col>
        </b-row>
        <hr class="my-3" />
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      required: false,
      default: false
    },
    stimulusList: {
      type: Array,
      required: false
    }
  },
  methods: {
    visitURL(url) {
      window.open(url, "_blank");
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.state;
      },
      set(val) {
        this.$emit("updateState", val);
      }
    }
  }
};
</script>

<style scoped>
video {
  width: auto;
  max-width: 200px;
  height: auto;
}
</style>
